@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Alex+Brush&family=Megrim&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Raleway", sans-serif;
    z-index: 0;
  }
}

/* Scrollbar Modification */
::-webkit-scrollbar {
  width: 6px; /* Adjust the width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: black /* Track background color */
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.6); /* Thumb color */
  border-radius: 10px; /* Rounded corners for the thumb */
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.911);
  border-radius: 10px; /* Thumb color on hover */
}

/* Media queries */
@media (max-width: 984px) {
  #hero-img {
    right: 80px;
    width: 250px;
  }
  #home div:first-child {
    left: 75px;
  }

  #about-text {
    font-size: 15px;
  }

  #project-div {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  #project-image {
    width: 45%;
  }
  #project-text {
    font-size: 15px;
  }
  #memoji {
    height: 300px;
  }
  #contacts {
    margin: 0 100px;
  }
  #greeting {
    font-size: 40px;
  }
  #Name {
    font-size: 35px;
  }
  #profile {
    font-size: 25px;
  }
  #typewriter {
    font-size: 25px;
  }
  #about-me {
    font-size: 15px;
  }
}

@media (max-width: 720px) {
  #hero-img {
    right: 75px;
    width: 200px;
  }
  #greeting {
    font-size: 35px;
  }
  #Name {
    font-size: 30px;
  }
  #profile {
    font-size: 20px;
  }
  #typewriter {
    font-size: 20px;
  }
  #about-me {
    font-size: 13px;
  }
  #home div:first-child {
    top: 160px;
  }
  #download-btn {
    font-size: 13px;
    padding: 6px;
  }
  #download-icon {
    width: 20px;
  }
  #about {
    margin-top: 5px;
  }
  #about-text {
    font-size: 13px;
  }
  #memoji {
    height: 250px;
  }
  #project-text {
    font-size: 13px;
  }
  #contacts {
    flex-direction: column;
  }
  #contact form input {
    width: 350px;
  }
  #contact form textarea {
    width: 350px;
  }
}

@media (max-width: 660px) {
  #logo {
    font-size: 40px;
  }
  #home {
    height: 400px;
  }
  #hero-img {
    display: none;
  }
  #home div:first-child {
    top: 119px;
    width: 72%;
    left: 14%;
    text-align: center;
  }
  #download-btn {
    font-size: 13px;
    padding: 5px;
  }
  #greeting {
    font-size: 30px;
  }
  #Name {
    font-size: 25px;
  }
  #profile {
    font-size: 16px;
  }
  #typewriter {
    font-size: 16px;
  }
  #about-me {
    font-size: 12px;
  }
  #download-icon {
    width: 17px;
  }
  #about-text {
    font-size: 12px;
  }
  #lets-connect {
    font-size: 20px;
  }
  .icons {
    width: 20px;
  }
  #about-text div {
    width: 150px;
  }
  #memoji {
    height: 230px;
  }
  #heading {
    font-size: 25px;
  }
  #exp-line {
    font-size: 16px;
  }
  #icon {
    width: 20px;
  }
  #project-div {
    flex-direction: column;
  }
  #Project-heading {
    font-size: 1.3rem;
  }
  #project-image {
    width: 75%;
    margin-top: 1.5rem;
    margin-left: 0px;
  }
  #project-text {
    width: 70%;
  }
  #project-buttons {
    width: 100%;
    justify-content: space-evenly;
  }
  #buttons button {
    font-size: 13px;
    margin: 10px;
    padding: 6px;
  }
  #contact form input {
    width: 300px;
    font-size: 15px;
    padding: 5px;
  }
  #contact form textarea {
    width: 300px;
    font-size: 15px;
    padding: 5px;
  }
  #contact form label {
    font-size: 15px;
  }
  #footer-div {
    font-size: 15px;
    gap: 6px;
    margin-top: 4px;
    flex-direction: column;
  }
  #contacts p {
    font-size: 15px;
  }
  #role-description li {
    font-size: 14px;
  }
}

@media (max-width: 548px) {
  #hero-img {
    display: none;
  }
  #home div:first-child {
    top: 119px;
    width: 72%;
    left: 14%;
    text-align: center;
  }
  #download-btn {
    font-size: 13px;
    padding: 5px;
    margin: auto;
  }
  #home {
    height: 420px;
  }
  #about-div {
    flex-direction: column;
    text-align: center;
  }
  #about-text div {
    width: 75%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #Project-heading {
    font-size: 1.5rem;
  }
  #project-text {
    width: 80%;
    text-align: center;
  }
  .icons {
    width: 45px;
  }
  #about-text {
    width: 75%;
  }

  #project-image {
    width: 80%;
  }
  #tech-button{
    margin: 6px;
    font-size: 12px;
  }
}
